import React, { useLayoutEffect } from "react";
import { Link } from "gatsby";
import { pStyles, visionGrey, technoOrange } from "../styles/global.module.css";
import { commonLayoutStyles, skipToContent } from "../styles/layout.module.css";

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import CookieConsent from "react-cookie-consent";

gsap.registerPlugin(ScrollToPlugin);

const layoutStyles = {
  display: "grid",
};

function Layout({ children, skipTo }) {
  const isBrowser = typeof window !== "undefined";
  const breakpoint = 725;

  useLayoutEffect(() => {
    // Check if window is defined (so if in the browser or in node.js).
    if (skipTo) {
      const skipToButton = document.getElementById("skip-to-content");
      skipToButton.addEventListener("click", skipToSection);

      function skipToSection() {
        if (isBrowser) {
          gsap.to(window, { duration: 1.5, scrollTo: `#${skipTo}` });
        }
      }

      return function cleanupListener() {
        skipToButton.removeEventListener("click", skipToSection);
      };
    }
  }, []);
  return (
    <React.Fragment>
      {skipTo && isBrowser && (
        <a
          id="skip-to-content"
          className={`${pStyles} ${skipToContent}`}
          href="#"
        >
          {window.innerWidth < breakpoint ? "Skip" : "Skip to Content"}
        </a>
      )}
      <link
        itemProp="thumbnailUrl"
        href="https://kloveandsitch.agency/wa_share_me.png"
      />
      <span
        itemProp="thumbnail"
        itemScope
        itemType="http://schema.org/ImageObject"
      >
        <link
          itemProp="url"
          href="https://kloveandsitch.agency/wa_share_me.png"
        />
      </span>
      <CookieConsent
        buttonText="I Accept"
        cookieName="KloveandSitchVisionCookie"
        style={{
          background: "#1A1A1A",
          backgroundImage: "url(/cookies.svg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "1em center",
          alignItems: "center",
          justifyContent: "center",
        }}
        buttonStyle={{
          backgroundColor: "#fc4d0d",
          fontFamily: "Source Code Pro, monospace",
        }}
        expires={150}
      >
        <span
          className={`${visionGrey} ${pStyles}`}
          style={{
            fontFamily: "Source Code Pro, monospace",
            textUnderlineOffset: "0.1em",
            margin: "0em 1em 0em 3em",
            display: "inline-block",
          }}
        >
          This website uses cookies to enhance your experience. View our{" "}
          <Link
            to="/compliance"
            state={{ modal: true }}
            className={`${technoOrange}`}
          >
            cookies policy
          </Link>{" "}
          for more information.
        </span>
      </CookieConsent>
      <div id="layout" className={commonLayoutStyles} style={layoutStyles}>
        {children}
      </div>
    </React.Fragment>
  );
}

export default Layout;
