import React, { useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";
import gsap from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

import {
  brandingStyles,
  logoScramblerStyles,
  ampersandStyles,
  logoStyles,
} from "../styles/branding.module.css";

gsap.registerPlugin(ScrambleTextPlugin);

function Branding({ getStandAloneTimelines }) {
  const tlBranding = useRef();

  useLayoutEffect(() => {
    gsap.set("#logo-graphic", { marginTop: 17, opacity: 0 });

    function removeElement(element) {
      if (document.getElementById(element) != null) {
        let el = document.querySelector(`#${element}`);
        el.remove();
      }
      return;
    }

    const logo = document.getElementById("logo");

    logo.addEventListener("mouseenter", spinAmpersand);

    function spinAmpersand() {
      gsap.fromTo(
        "#spinner",
        { rotation: 0 },
        {
          duration: 0.5,
          rotation: 360,
          transformOrigin: "50% 50%",
        }
      );
    }

    tlBranding.current = gsap
      .timeline()
      .to("#logo-scrambler", {
        duration: 0.5,
        opacity: 0,
        scrambleText: {
          text: "!/&%{}]?#()",
          chars: "!/&%{}]?#()",
        },
        onComplete: removeElement,
        onCompleteParams: ["logo-scrambler"],
      })
      .to("#logo-graphic", { duration: 1, opacity: 1 }, "-=0.2")
      .from("#dot", { duration: 0.5, fill: "#1A1A1A", repeat: 8 }, "-=1.6");

    getStandAloneTimelines(tlBranding.current, "tl-branding");

    return function cleanupListener() {
      logo.removeEventListener("mouseenter", spinAmpersand);

      try {
        tlBranding.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <div id="branding" className={`${brandingStyles}`}>
      <p id="logo-scrambler" className={logoScramblerStyles}></p>
      <Link id="logo" to="/">
        <svg
          id="logo-graphic"
          className={logoStyles}
          width="247"
          height="89"
          viewBox="0 0 247 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.182037 28.8835H5.12415V43.0287L18.5052 28.8835H24.5739L12.8795 40.9797L25.0952 57.0126H19.108L9.50378 44.3555L5.12415 48.8545V57.0126H0.182037V28.8835Z"
            fill="white"
          />
          <path
            d="M26.8239 27.6796H31.6853V57.0127H26.8239V27.6796Z"
            fill="white"
          />
          <path
            d="M52.4206 46.445C52.4206 42.7086 49.7276 39.6137 45.9104 39.6137C42.0126 39.6137 39.5222 42.6674 39.5222 46.445C39.5222 50.1403 42.2135 53.2763 45.9911 53.2763C49.9293 53.2763 52.4206 50.1814 52.4206 46.445ZM34.6599 46.445C34.6599 40.3771 39.481 35.3543 45.9911 35.3543C52.5005 35.3543 57.282 40.2964 57.282 46.445C57.282 52.4717 52.4609 57.4945 45.9104 57.4945C39.4406 57.4945 34.6599 52.5524 34.6599 46.445"
            fill="white"
          />
          <path
            d="M56.8014 35.7951H62.0243L67.7307 51.3874L73.4774 35.7951H78.5801L69.9007 57.1728H65.5211L56.8014 35.7951Z"
            fill="white"
          />
          <path
            d="M93.8502 44.9169C93.529 41.7833 91.681 39.3333 88.4658 39.3333C85.492 39.3333 83.4027 41.6235 82.9604 44.9169H93.8502ZM78.1385 46.4042C78.1385 40.3372 82.4391 35.3547 88.5062 35.3547C95.2568 35.3547 98.6729 40.6575 98.6729 46.7666C98.6729 47.087 98.6317 47.8907 98.5922 48.1329H83.0016C83.5229 51.5885 85.9746 53.5156 89.109 53.5156C91.48 53.5156 93.1675 52.6319 94.8549 50.9856L97.7085 53.5156C95.6991 55.9278 92.9262 57.4941 89.0291 57.4941C82.8805 57.4941 78.1385 53.0338 78.1385 46.4042"
            fill="white"
          />
          <path
            d="M159.106 52.9135L162.079 49.378C164.772 51.7095 167.463 53.0337 170.96 53.0337C174.015 53.0337 175.943 51.6271 175.943 49.4184C175.943 47.4089 174.817 46.3243 169.594 45.0791C163.607 43.6716 160.231 41.9043 160.231 36.6394C160.231 31.8183 164.249 28.4821 169.834 28.4821C173.933 28.4821 177.188 29.729 180.041 32.0193L177.389 35.7557C174.858 33.8673 172.326 32.8617 169.755 32.8617C166.861 32.8617 165.173 34.3491 165.173 36.2795C165.173 38.4495 166.459 39.4122 171.844 40.6986C177.791 42.1448 180.885 44.2753 180.885 49.0173C180.885 54.2806 176.746 57.415 170.84 57.415C166.54 57.415 162.482 55.9277 159.106 52.9135Z"
            fill="white"
          />
          <path
            id="i"
            d="M184.222 35.7948H189.083V57.0127H184.222V35.7948ZM184.021 0Z"
            fill="white"
          />
          <path
            d="M194.227 51.1458V39.9752H191.534V35.7949H194.227V29.9683H199.09V35.7949H204.795V39.9752H199.09V50.3824C199.09 52.2716 200.053 53.0342 201.701 53.0342C202.786 53.0342 203.75 52.7945 204.714 52.3119V56.2889C203.51 56.9716 202.143 57.3735 200.335 57.3735C196.759 57.3735 194.227 55.8071 194.227 51.1458Z"
            fill="white"
          />
          <path
            d="M205.719 46.445C205.719 40.4183 210.38 35.3543 216.728 35.3543C220.747 35.3543 223.239 36.8408 225.208 39.0108L222.194 42.2655C220.707 40.6983 219.14 39.6137 216.689 39.6137C213.153 39.6137 210.581 42.6674 210.581 46.445C210.581 50.2226 213.193 53.2763 216.929 53.2763C219.221 53.2763 220.908 52.1917 222.436 50.6245L225.367 53.516C223.279 55.8483 220.827 57.4945 216.689 57.4945C210.38 57.4945 205.719 52.5524 205.719 46.445Z"
            fill="white"
          />
          <path
            d="M227.62 27.6795H232.482V39.0906C233.847 37.0828 235.816 35.3541 239.112 35.3541C243.894 35.3541 246.667 38.5684 246.667 43.5106V57.0126H241.804V44.9575C241.804 41.6625 240.157 39.7741 237.263 39.7741C234.451 39.7741 232.482 41.7424 232.482 45.0382V57.0126H227.62V27.6795Z"
            fill="white"
          />
          <rect
            id="dot"
            x="184.019"
            y="27.9204"
            width="5.22599"
            height="4.61886"
            fill="#FC4D0D"
          />
        </svg>
        <svg
          className={`${ampersandStyles}`}
          width="247"
          height="89"
          viewBox="0 0 247 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M133.996 44.5566C138.828 40.6275 147.075 32.8433 148.785 24.4151H142.626C141.048 29.489 136.034 34.847 131.827 38.5373V24.4143H125.847V38.735C116.966 31.5849 113.246 23.7555 115.611 16.7528C117.546 11.0176 123.34 6.9353 129.343 6.9353C129.657 6.9353 129.974 6.94684 130.29 6.96825C134.987 7.30838 138.728 10.2213 141.08 15.1675L145.494 10.7533C142.135 4.95796 136.992 1.45706 130.722 1.00329C121.756 0.351863 112.825 6.30282 109.943 14.8389C108.3 19.7069 106.612 31.9572 123.85 44.7683C119.018 48.6974 110.772 56.4824 109.062 64.9106H115.22C116.777 59.9059 121.677 54.6229 125.847 50.9367V64.9106H131.827V50.4516C140.832 57.6387 144.616 65.525 142.236 72.5737C140.198 78.6087 133.881 82.8137 127.557 82.3575C122.825 82.0132 119.064 79.0633 116.715 74.052L112.293 78.4745C115.651 84.3299 120.819 87.8654 127.124 88.3233C127.572 88.3562 128.02 88.371 128.466 88.371C136.965 88.371 145.165 82.5971 147.904 74.4877C149.547 69.6197 151.235 57.3694 133.996 44.5566Z"
            fill="white"
          />
        </svg>
        <svg
          className={`${ampersandStyles}`}
          width="247"
          height="89"
          viewBox="0 0 247 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M133.996 44.5566C138.828 40.6275 147.075 32.8433 148.785 24.4151H142.626C141.048 29.489 136.034 34.847 131.827 38.5373V24.4143H125.847V38.735C116.966 31.5849 113.246 23.7555 115.611 16.7528C117.546 11.0176 123.34 6.9353 129.343 6.9353C129.657 6.9353 129.974 6.94684 130.29 6.96825C134.987 7.30838 138.728 10.2213 141.08 15.1675L145.494 10.7533C142.135 4.95796 136.992 1.45706 130.722 1.00329C121.756 0.351863 112.825 6.30282 109.943 14.8389C108.3 19.7069 106.612 31.9572 123.85 44.7683C119.018 48.6974 110.772 56.4824 109.062 64.9106H115.22C116.777 59.9059 121.677 54.6229 125.847 50.9367V64.9106H131.827V50.4516C140.832 57.6387 144.616 65.525 142.236 72.5737C140.198 78.6087 133.881 82.8137 127.557 82.3575C122.825 82.0132 119.064 79.0633 116.715 74.052L112.293 78.4745C115.651 84.3299 120.819 87.8654 127.124 88.3233C127.572 88.3562 128.02 88.371 128.466 88.371C136.965 88.371 145.165 82.5971 147.904 74.4877C149.547 69.6197 151.235 57.3694 133.996 44.5566Z"
            fill="white"
          />
        </svg>
        <svg
          id="spinner"
          className={`${ampersandStyles}`}
          width="247"
          height="89"
          viewBox="0 0 247 89"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M133.996 44.5566C138.828 40.6275 147.075 32.8433 148.785 24.4151H142.626C141.048 29.489 136.034 34.847 131.827 38.5373V24.4143H125.847V38.735C116.966 31.5849 113.246 23.7555 115.611 16.7528C117.546 11.0176 123.34 6.9353 129.343 6.9353C129.657 6.9353 129.974 6.94684 130.29 6.96825C134.987 7.30838 138.728 10.2213 141.08 15.1675L145.494 10.7533C142.135 4.95796 136.992 1.45706 130.722 1.00329C121.756 0.351863 112.825 6.30282 109.943 14.8389C108.3 19.7069 106.612 31.9572 123.85 44.7683C119.018 48.6974 110.772 56.4824 109.062 64.9106H115.22C116.777 59.9059 121.677 54.6229 125.847 50.9367V64.9106H131.827V50.4516C140.832 57.6387 144.616 65.525 142.236 72.5737C140.198 78.6087 133.881 82.8137 127.557 82.3575C122.825 82.0132 119.064 79.0633 116.715 74.052L112.293 78.4745C115.651 84.3299 120.819 87.8654 127.124 88.3233C127.572 88.3562 128.02 88.371 128.466 88.371C136.965 88.371 145.165 82.5971 147.904 74.4877C149.547 69.6197 151.235 57.3694 133.996 44.5566Z"
            fill="white"
          />
        </svg>
      </Link>
    </div>
  );
}

export default Branding;
