// extracted by mini-css-extract-plugin
export var relative = "global-module--relative--p-fxU";
export var hiddenStyles = "global-module--hiddenStyles--dSgBQ";
export var technoOrange = "global-module--technoOrange--qYQ+m";
export var matrixBlack = "global-module--matrixBlack--gJU-J";
export var visionGrey = "global-module--visionGrey--ixSPh";
export var rightAlignStyles = "global-module--rightAlignStyles--aThdi";
export var centerAlignStyles = "global-module--centerAlignStyles--mhN-C";
export var taglineStyles = "global-module--taglineStyles--ibs9r";
export var pStyles = "global-module--pStyles---kLxD";
export var h1Styles = "global-module--h1Styles--kmL4X";
export var h2Styles = "global-module--h2Styles--z9xgf";
export var h2InnerStyles = "global-module--h2InnerStyles--hpWUG";
export var h3InnerStyles = "global-module--h3InnerStyles--tEePF";
export var offsetLeftStyles = "global-module--offsetLeftStyles--xgsvV";
export var offsetRightStyles = "global-module--offsetRightStyles----6yF";
export var rightAmpersandStyles = "global-module--rightAmpersandStyles--ayoRr";
export var termStyles = "global-module--termStyles--Ik3n9";
export var glitchStylesVideo = "global-module--glitchStylesVideo--gCnnS";
export var breakStyles = "global-module--breakStyles--opOsC";
export var glitch = "global-module--glitch--ucN4z";
export var glitchAfter = "global-module--glitch-after--olYKa";
export var glitchBefore = "global-module--glitch-before--368ZT";