import React from "react";

import { mainContentStyles } from "../styles/main-content.module.css";

function MainContent({ children }) {
  return (
    <main id="content" className={mainContentStyles}>
      {children}
    </main>
  );
}

export default MainContent;
